var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        { staticClass: "mb-3" },
        [
          _c("b-col", { attrs: { cols: "3" } }, [_vm._v(" Font Size ")]),
          _c(
            "b-col",
            { attrs: { cols: "5" } },
            [
              _c("b-form-input", {
                attrs: {
                  type: "range",
                  min: "0",
                  max: _vm.fontSizeMax,
                  step: _vm.fontSizeSteps
                },
                on: { change: _vm.dataHasChanged },
                model: {
                  value: _vm.fontSize,
                  callback: function($$v) {
                    _vm.fontSize = $$v
                  },
                  expression: "fontSize"
                }
              })
            ],
            1
          ),
          _c(
            "b-col",
            {
              staticClass: "d-flex position-relative",
              staticStyle: { top: "-8px", left: "0" },
              attrs: { cols: "4" }
            },
            [
              _c("b-form-input", {
                staticClass: "d-inline-block mr-1",
                attrs: { type: "text" },
                on: { update: _vm.dataHasChanged },
                model: {
                  value: _vm.fontSize,
                  callback: function($$v) {
                    _vm.fontSize = $$v
                  },
                  expression: "fontSize"
                }
              }),
              _c("b-form-select", {
                attrs: { options: _vm.fontSizeTypes },
                on: { change: _vm.dataHasChanged },
                model: {
                  value: _vm.fontSizeType,
                  callback: function($$v) {
                    _vm.fontSizeType = $$v
                  },
                  expression: "fontSizeType"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-3" },
        [
          _c("b-col", { attrs: { cols: "3" } }, [_vm._v(" Line Height ")]),
          _c(
            "b-col",
            { attrs: { cols: "5" } },
            [
              _c("b-form-input", {
                attrs: {
                  type: "range",
                  min: "0",
                  max: _vm.lineHeightMax,
                  step: _vm.lineHeightSteps
                },
                on: { change: _vm.dataHasChanged },
                model: {
                  value: _vm.lineHeight,
                  callback: function($$v) {
                    _vm.lineHeight = $$v
                  },
                  expression: "lineHeight"
                }
              })
            ],
            1
          ),
          _c(
            "b-col",
            {
              staticClass: "d-flex position-relative",
              staticStyle: { top: "-8px", left: "0" },
              attrs: { cols: "4" }
            },
            [
              _c("b-form-input", {
                staticClass: "d-inline-block mr-1",
                attrs: { type: "text" },
                on: { update: _vm.dataHasChanged },
                model: {
                  value: _vm.lineHeight,
                  callback: function($$v) {
                    _vm.lineHeight = $$v
                  },
                  expression: "lineHeight"
                }
              }),
              _c("b-form-select", {
                attrs: { options: _vm.lineHeightTypes },
                on: { change: _vm.dataHasChanged },
                model: {
                  value: _vm.lineHeightType,
                  callback: function($$v) {
                    _vm.lineHeightType = $$v
                  },
                  expression: "lineHeightType"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-3" },
        [
          _c("b-col", { attrs: { cols: "3" } }, [_vm._v(" Font Weight ")]),
          _c(
            "b-col",
            { attrs: { cols: "5" } },
            [
              _c("b-form-input", {
                attrs: { type: "range", min: "100", max: "700", step: "100" },
                on: { change: _vm.dataHasChanged },
                model: {
                  value: _vm.fontWeight,
                  callback: function($$v) {
                    _vm.fontWeight = $$v
                  },
                  expression: "fontWeight"
                }
              })
            ],
            1
          ),
          _c(
            "b-col",
            {
              staticClass: "position-relative",
              staticStyle: { top: "-8px", left: "0" },
              attrs: { cols: "4" }
            },
            [
              _c("b-form-input", {
                staticClass: "d-inline-block mr-1",
                attrs: { type: "text", disabled: "" },
                model: {
                  value: _vm.fontWeight,
                  callback: function($$v) {
                    _vm.fontWeight = $$v
                  },
                  expression: "fontWeight"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { cols: "3" } }, [_vm._v(" Spacing ")]),
          _c(
            "b-col",
            { attrs: { cols: "5" } },
            [
              _c("b-form-input", {
                attrs: {
                  type: "range",
                  min: _vm.spacingMin,
                  max: _vm.spacingMax,
                  step: _vm.spacingSteps
                },
                on: { change: _vm.dataHasChanged },
                model: {
                  value: _vm.spacing,
                  callback: function($$v) {
                    _vm.spacing = $$v
                  },
                  expression: "spacing"
                }
              })
            ],
            1
          ),
          _c(
            "b-col",
            {
              staticClass: "d-flex position-relative",
              staticStyle: { top: "-8px", left: "0" },
              attrs: { cols: "4" }
            },
            [
              _c("b-form-input", {
                staticClass: "d-inline-block mr-1",
                attrs: { type: "text" },
                on: { update: _vm.dataHasChanged },
                model: {
                  value: _vm.spacing,
                  callback: function($$v) {
                    _vm.spacing = $$v
                  },
                  expression: "spacing"
                }
              }),
              _c("b-form-select", {
                attrs: { options: _vm.spacingTypes },
                on: { change: _vm.dataHasChanged },
                model: {
                  value: _vm.spacingType,
                  callback: function($$v) {
                    _vm.spacingType = $$v
                  },
                  expression: "spacingType"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }