<template>
  <div>
    <b-row class="mb-3">
      <b-col cols="3">
        Font Size
      </b-col>
      <b-col cols="5">
        <b-form-input
          type="range"
          v-model="fontSize"
          min="0" :max="fontSizeMax"
          :step="fontSizeSteps"
          @change="dataHasChanged"
        ></b-form-input>
      </b-col>
      <b-col cols="4" class="d-flex position-relative" style="top: -8px; left: 0;">
        <b-form-input
          class="d-inline-block mr-1"
          type="text"
          v-model="fontSize"
          @update="dataHasChanged"
        ></b-form-input>
        <b-form-select
          v-model="fontSizeType"
          :options="fontSizeTypes"
          @change="dataHasChanged"
        ></b-form-select>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col cols="3">
        Line Height
      </b-col>
      <b-col cols="5">
        <b-form-input
          type="range"
          v-model="lineHeight"
          min="0" :max="lineHeightMax"
          :step="lineHeightSteps"
          @change="dataHasChanged"
        ></b-form-input>
      </b-col>
      <b-col cols="4" class="d-flex position-relative" style="top: -8px; left: 0;">
        <b-form-input
          class="d-inline-block mr-1"
          type="text"
          v-model="lineHeight"
          @update="dataHasChanged"
        ></b-form-input>
        <b-form-select
          v-model="lineHeightType"
          :options="lineHeightTypes"
          @change="dataHasChanged"
        ></b-form-select>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col cols="3">
        Font Weight
      </b-col>
      <b-col cols="5">
        <b-form-input
          type="range"
          v-model="fontWeight"
          min="100" max="700"
          step="100"
          @change="dataHasChanged"
        ></b-form-input>
      </b-col>
      <b-col cols="4" class="position-relative" style="top: -8px; left: 0;">
        <b-form-input
          class="d-inline-block mr-1"
          type="text"
          v-model="fontWeight"
          disabled
        ></b-form-input>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="3">
        Spacing
      </b-col>
      <b-col cols="5">
        <b-form-input
          type="range"
          v-model="spacing"
          :min="spacingMin" :max="spacingMax"
          :step="spacingSteps"
          @change="dataHasChanged"
        ></b-form-input>
      </b-col>
      <b-col cols="4" class="d-flex position-relative" style="top: -8px; left: 0;">
        <b-form-input
          class="d-inline-block mr-1"
          type="text"
          v-model="spacing"
          @update="dataHasChanged"
        ></b-form-input>
        <b-form-select
          v-model="spacingType"
          :options="spacingTypes"
          @change="dataHasChanged"
        ></b-form-select>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'ModalContentFontSettings',
  data() {
    return {
      fontSize: null,
      fontSizeType: 'px',
      fontSizeTypes: [
        'px',
        'em',
        'rem',
      ],
      lineHeight: null,
      lineHeightType: '',
      lineHeightTypes: [
        '',
        'px',
      ],
      fontWeight: null,
      spacing: null,
      spacingType: 'px',
      spacingTypes: [
        'px',
        'em',
        'rem',
      ],
    };
  },
  props: ['deviceType', 'styles'],
  created() {
    if (this.styles) {
      const fontSizeObject = this.parseStyleString(this.styles.fontSize);
      this.fontSize = fontSizeObject.value;
      this.fontSizeType = fontSizeObject.type;
      const lineHeightObject = this.parseStyleString(this.styles.lineHeight);
      this.lineHeight = lineHeightObject.value;
      this.lineHeightType = lineHeightObject.type || '';
      this.fontWeight = this.styles.fontWeight;
      const spacingObj = this.parseStyleString(this.styles.letterSpacing);
      this.spacing = spacingObj.value;
      this.spacingType = spacingObj.type;
    }
  },
  methods: {
    parseStyleString(styleValue) {
      let value = 0;
      const intValueObj = /[0-9]+/.exec(styleValue);
      const decValueObj = /\.\d+/.exec(styleValue);

      if (intValueObj !== null && decValueObj !== null) {
        value = intValueObj[0] + decValueObj[0];
      } else if (intValueObj) {
        [value] = intValueObj;
      }
      const typeObj = /[a-z]+/.exec(styleValue);
      const type = (typeObj) ? typeObj[0] : null;
      return {
        value,
        type,
      };
    },
    dataHasChanged() {
      this.$emit('data-has-changed', {
        deviceType: this.deviceType,
        data: this.typographyData,
      });
    },
  },
  computed: {
    typographyData() {
      return {
        fontSize: (this.fontSize) ? `${this.fontSize}${this.fontSizeType}` : '0',
        lineHeight: (this.lineHeight) ? `${this.lineHeight}${this.lineHeightType}` : '0',
        fontWeight: this.fontWeight,
        spacing: (this.spacing) ? `${this.spacing}${this.spacingType}` : `0${this.spacingType}`,
      };
    },
    fontSizeSteps() {
      let fontSizeStep = null;
      // eslint-disable-next-line default-case
      switch (this.fontSizeType) {
        case 'px':
          fontSizeStep = 1;
        // eslint-disable-next-line no-fallthrough
        case 'em':
        case 'rem':
          fontSizeStep = 0.1;
      }
      return fontSizeStep;
    },
    fontSizeMax() {
      // eslint-disable-next-line default-case
      switch (this.fontSizeType) {
        case 'px':
          return 100;
        case 'em':
        case 'rem':
          return 10;
      }
      return 100;
    },
    lineHeightSteps() {
      switch (this.lineHeightType) {
        case 'px':
          return 1;
        default:
          return 0.1;
      }
    },
    lineHeightMax() {
      switch (this.lineHeightType) {
        case 'px':
          return 50;
        default:
          return 4;
      }
    },
    spacingSteps() {
      switch (this.spacingType) {
        case 'px':
          return 0.01;
        default:
          return 0.001;
      }
    },
    spacingMin() {
      switch (this.spacingType) {
        case 'px':
          return -10;
        default:
          return -0.625;
      }
    },
    spacingMax() {
      switch (this.spacingType) {
        case 'px':
          return 10;
        default:
          return 0.625;
      }
    },
  },
};
</script>
